@font-face {
    font-family: 'AritaBuri';
    font-weight: 100;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Thin.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Thin.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Thin.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Thin.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Thin.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'AritaBuri';
    font-weight: 300;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Light.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Light.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Light.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Light.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Light.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'AritaBuri';
    font-weight: 500;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Medium.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Medium.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Medium.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Medium.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Medium.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'AritaBuri';
    font-weight: 600;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-SemiBold.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-SemiBold.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-SemiBold.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-SemiBold.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'AritaBuri';
    font-weight: 700;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Bold.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Bold.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Bold.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Bold.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaBuri-Bold.ttf') format("truetype");
    font-display: swap;
}


@font-face {
    font-family: 'AritaDotum';
    font-weight: 100;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Thin.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Thin.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Thin.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Thin.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Thin.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'AritaDotum';
    font-weight: 300;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Light.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Light.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Light.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Light.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Light.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'AritaDotum';
    font-weight: 500;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Medium.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Medium.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Medium.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Medium.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Medium.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'AritaDotum';
    font-weight: 600;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-SemiBold.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-SemiBold.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-SemiBold.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-SemiBold.ttf') format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'AritaDotum';
    font-weight: 700;
    font-style: normal;
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Bold.eot');
    src: url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Bold.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Bold.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Bold.woff') format('woff'),
        url('https://cdn.jsdelivr.net/gh/webfontworld/amore/AritaDotum-Bold.ttf') format("truetype");
    font-display: swap;
}